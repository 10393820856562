import { CSSProperties, FC, useCallback } from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { ButtonPrimary } from "components/AppComponents/AppButton/AppButton";
import useAlertAndTransactionContext from "context/alertAndTransaction";
import useConnector, { ERC20TokenType } from "context/connector";

import DoneIcon from "@mui/icons-material/Done";
import WalletIcon from "@mui/icons-material/Wallet";
import {
  BaseDialogContentWrapper,
  BaseDialogWrapper,
} from "components/Base/Dialog/StyledDialog";
import { BaseDialogTitle } from "components/Base/Dialog/BaseDialogTitle";
import { Link } from "react-router-dom";
import { TransactionType } from "fathom-sdk";

export const SuccessContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
    height: 80%;
  }
`;

export const SuccessIconWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2a3e5a;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-bottom: 20px;
`;
const ModalTitle = styled(Typography)`
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.235;
`;

const SuccessMessage = styled(Typography)`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    margin-bottom: 25px;
  }
`;

const GoToLink = styled(Link)`
  color: #43fff1;
`;

export enum GoToLinksVariants {
  DEX_FXD = "dex_fxd",
  DEX_FTHM = "dex_fthm",
  LENDING = "lending",
}

const SecondaryMarketDescription = styled(Box)`
  padding: 0 20px;
  text-align: center;
  margin-top: -20px;

  strong {
    margin-bottom: 10px;
    display: inline-block;
  }
`;

export const GoToLinks: FC<{
  variant?: GoToLinksVariants;
  sx?: CSSProperties;
}> = ({ variant, sx }) => {
  const {
    erc20TransactionType,
    resetErc20TokenModal,
    showStakingModal,
    setShowStakingModal,
  } = useAlertAndTransactionContext();

  if (variant === GoToLinksVariants.DEX_FTHM) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          ...sx,
        }}
      >
        <GoToLink to={"/dao/staking"} onClick={() => resetErc20TokenModal()}>
          Stake in DAO
        </GoToLink>
      </Box>
    );
  }

  if (showStakingModal) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          ...sx,
        }}
      >
        <GoToLink
          to={"/dao/governance"}
          onClick={() => setShowStakingModal(false)}
        >
          Participate in Governance
        </GoToLink>
      </Box>
    );
  }

  if (
    erc20TransactionType === TransactionType.OpenPosition ||
    variant === GoToLinksVariants.DEX_FXD
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 20px",
          ...sx,
        }}
      >
        <GoToLink to={"/vaults"} onClick={() => resetErc20TokenModal()}>
          Deposit to vaults
        </GoToLink>
        <GoToLink to={"/lending"} onClick={() => resetErc20TokenModal()}>
          Deposit to lending
        </GoToLink>
      </Box>
    );
  }

  if (
    erc20TransactionType === TransactionType.OpenVaultDeposit ||
    variant === GoToLinksVariants.LENDING
  ) {
    return (
      <SecondaryMarketDescription sx={sx}>
        <strong>A secondary market for share tokens is launching soon!</strong>{" "}
        <br /> This will enable easier trading and liquidity for your holdings.{" "}
        <br />
        Be ready to buy, sell, and trade share tokens directly on DEX. <br />{" "}
        Stay tuned for updates! <br />
        <GoToLink to={"/swap"} onClick={() => resetErc20TokenModal()}>
          Go to DEX
        </GoToLink>
      </SecondaryMarketDescription>
    );
  }

  return null;
};

export const TransactionErc20TokenModal: FC = () => {
  const {
    erc20TokenModalData,
    successAlertMessage,
    erc20TokenModalDescription,
    resetErc20TokenModal,
  } = useAlertAndTransactionContext();

  const { addERC20Token, isMetamask } = useConnector();

  const { image } = erc20TokenModalData as ERC20TokenType;

  const addTokenToMetamaskWalletHandler = useCallback(() => {
    addERC20Token(erc20TokenModalData as ERC20TokenType);
  }, [addERC20Token]);

  return (
    <BaseDialogWrapper
      onClose={resetErc20TokenModal}
      aria-labelledby="customized-dialog-title"
      open={true}
      color="primary"
      maxWidth="xsm"
    >
      <SuccessContentWrapper>
        <BaseDialogTitle
          id="customized-dialog-title"
          onClose={resetErc20TokenModal}
        ></BaseDialogTitle>
        <SuccessIconWrapper>
          <DoneIcon color="success" />
        </SuccessIconWrapper>
        <ModalTitle variant="h1">All done!</ModalTitle>
        <SuccessMessage>{successAlertMessage}</SuccessMessage>
        {isMetamask && (
          <BaseDialogContentWrapper
            width={"80%"}
            sx={{
              alignItems: "center",
              textAlign: "center",
              margin: "30px 15px",
            }}
          >
            {image && <img src={image} alt={"fxd"} width={28} height={28} />}
            <Typography variant={"body2"} component="span">
              {erc20TokenModalDescription}
            </Typography>
            <ButtonPrimary onClick={addTokenToMetamaskWalletHandler}>
              <WalletIcon sx={{ fontSize: "16px", marginRight: "7px" }} />
              Add to wallet
            </ButtonPrimary>
          </BaseDialogContentWrapper>
        )}
      </SuccessContentWrapper>
      <GoToLinks />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: {
            sm: "20px",
            xs: "0",
          },
        }}
        className="TxActionsWrapper"
      >
        <Button
          onClick={resetErc20TokenModal}
          variant="gradient"
          size="large"
          sx={{ minHeight: "44px" }}
          data-cy="closeButton"
        >
          Ok, Close
        </Button>
      </Box>
    </BaseDialogWrapper>
  );
};

export const TransactionStakingPositionModal: FC = () => {
  const { setShowStakingModal } = useAlertAndTransactionContext();

  return (
    <BaseDialogWrapper
      onClose={() => setShowStakingModal(false)}
      aria-labelledby="customized-dialog-title"
      open={true}
      color="primary"
      maxWidth="xsm"
    >
      <SuccessContentWrapper>
        <BaseDialogTitle
          id="customized-dialog-title"
          onClose={() => setShowStakingModal(false)}
        ></BaseDialogTitle>
        <SuccessIconWrapper>
          <DoneIcon color="success" />
        </SuccessIconWrapper>
        <SuccessMessage
          sx={{
            margin: "10px 20px 30px",
          }}
        >
          Staking position created successfully!
        </SuccessMessage>
      </SuccessContentWrapper>
      <GoToLinks />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: {
            sm: "20px",
            xs: "0",
          },
        }}
        className="TxActionsWrapper"
      >
        <Button
          onClick={() => setShowStakingModal(false)}
          variant="gradient"
          size="large"
          sx={{ minHeight: "44px" }}
          data-cy="closeButton"
        >
          Ok, Close
        </Button>
      </Box>
    </BaseDialogWrapper>
  );
};
