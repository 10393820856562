import { Box, Divider, ListItemText } from "@mui/material";
import BigNumber from "bignumber.js";

import useClosePositionContext from "context/repayPosition";
import {
  formatNumber,
  formatNumberPrice,
  formatPercentage,
} from "utils/format";

import BasePopover from "components/Base/Popover/BasePopover";
import { BaseSummary } from "components/Base/Typography/StyledTypography";
import {
  InfoListItem,
  ListTitleWrapper,
} from "components/Positions/OpenPosition/OpenPositionInfo";
import {
  BaseDialogFormInfoWrapper,
  BaseFormInfoList,
} from "components/Base/Form/StyledForm";

const RepayPositionInfo = () => {
  const {
    lockedCollateral,
    price,
    fathomToken,
    pool,
    collateral,
    liquidationPrice,
    overCollateral,
    safetyBuffer,
  } = useClosePositionContext();

  return (
    <BaseDialogFormInfoWrapper>
      <BaseSummary>Summary</BaseSummary>
      <Divider />
      <BaseFormInfoList>
        <InfoListItem
          alignItems="flex-start"
          secondaryAction={
            <>
              {formatNumber(
                BigNumber(lockedCollateral).multipliedBy(price).toNumber()
              )}{" "}
              FXD{" "}
              <Box component="span" sx={{ color: "#29C20A" }}>
                →{" "}
                {formatNumber(
                  Math.abs(
                    BigNumber(lockedCollateral)
                      .multipliedBy(price)
                      .minus(fathomToken)
                      .toNumber()
                  )
                )}{" "}
                FXD
              </Box>
            </>
          }
        >
          <ListItemText primary="FXD Borrowed" />
        </InfoListItem>
        <InfoListItem
          alignItems="flex-start"
          secondaryAction={
            <>
              {formatNumber(BigNumber(lockedCollateral).toNumber())}{" "}
              {pool?.poolName}{" "}
              <Box component="span" sx={{ color: "#29C20A" }}>
                →{" "}
                {formatNumber(
                  BigNumber(lockedCollateral)
                    .minus(collateral || 0)
                    .toNumber()
                )}{" "}
                {pool?.poolName}
              </Box>
            </>
          }
        >
          <ListItemText primary="Collateral Locked" />
        </InfoListItem>
        <InfoListItem
          alignItems={"flex-start"}
          secondaryAction={`${formatNumber(overCollateral)} %`}
        >
          <ListItemText
            primary={
              <ListTitleWrapper>
                Collateralization Ratio
                <BasePopover
                  id={"collateralization-ratio"}
                  text={
                    <>
                      Collateralization Ratio is the percentage of the total
                      value of your Collateral compared to the amount you've
                      borrowed. A higher ratio indicates a larger safety buffer,
                      reducing liquidation risk. If this ratio falls below the
                      minimum required level due to market fluctuations, your
                      Collateral could be liquidated to repay the loan.
                    </>
                  }
                />
              </ListTitleWrapper>
            }
          />
        </InfoListItem>
        {BigNumber(safetyBuffer).isGreaterThan(0) && (
          <InfoListItem
            alignItems="flex-start"
            secondaryAction={`${formatPercentage(
              BigNumber(safetyBuffer).multipliedBy(100).toNumber()
            )} %`}
          >
            <ListItemText
              primary={
                <ListTitleWrapper>
                  Safety Buffer
                  <BasePopover
                    id={"safety-buffer"}
                    text={
                      <>
                        Safety Buffer represents the extra collateral value
                        above your borrowed amount. This is maintained to
                        protect against market volatility and prevent the
                        automatic liquidation of your assets. The larger your
                        safety buffer, the lower your risk of reaching the
                        liquidation price. <br />
                        <br />
                        Safety buffer is calculated from LTV. When you multiply
                        your collateral value with LTV - you will get how much
                        you can borrow maximum with a 0% safety buffer. For
                        example, if your collateral value is $100, with 25% LTV,
                        you can maximum borrow 75 FXD, which gives you 0% Safety
                        Buffer, and your position becomes very risky for
                        liquidation. <br />
                        <br />
                        We recommend at least 50% Safety Buffer. Using the
                        example above, the recommended amount to borrow is 75
                        FXD * 50% = 37.5 FXD.
                      </>
                    }
                  />
                </ListTitleWrapper>
              }
            />
          </InfoListItem>
        )}
        <InfoListItem
          alignItems="flex-start"
          secondaryAction={`1 ${pool?.poolName} = ${formatNumberPrice(
            liquidationPrice
          )} FXD`}
        >
          <ListItemText
            primary={
              <ListTitleWrapper>
                Liquidation Price of {pool?.poolName}
                <BasePopover
                  id={"liquidation-price"}
                  text={
                    <>
                      Liquidation Price of XDC - Liquidation Price is the price
                      of the collateral token when your Collateral will be
                      automatically sold to partially or fully repay the loan if
                      your collateral value drops. It's a safety mechanism to
                      ensure that loans are always sufficiently collateralized.
                      Monitoring this price helps prevent the unwanted
                      liquidation of your assets.
                    </>
                  }
                />
              </ListTitleWrapper>
            }
          />
        </InfoListItem>
      </BaseFormInfoList>
    </BaseDialogFormInfoWrapper>
  );
};

export default RepayPositionInfo;
