import {
  ChangeEvent,
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useServices } from "context/services";
import { ICollateralPool, IOpenPosition } from "fathom-sdk";

import { COUNT_PER_PAGE } from "utils/Constants";
import useConnector from "context/connector";
import BigNumber from "bignumber.js";
import debounce from "lodash.debounce";
import useDashboard from "context/fxd";

const useOpenPositionList = (
  setPositionCurrentPage: Dispatch<number>,
  proxyWallet: string
) => {
  const { positionService } = useServices();
  const { chainId, account } = useConnector();
  const [formattedPositions, setFormattedPositions] = useState<IOpenPosition[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    loadingPools,
    poolsData,
    loadPositions,
    loadingPositions,
    positionsData,
    calledPositions,
    fetchMorePositions,
  } = useDashboard();

  const poolsDataArray = useMemo(() => {
    if (!loadingPools && poolsData && poolsData.pools.length) {
      return poolsData.pools.map((poolItem: ICollateralPool) => {
        return poolItem;
      });
    } else {
      return [];
    }
  }, [poolsData, loadingPools, chainId]);

  const [closePosition, setClosePosition] = useState<IOpenPosition>();
  const [topUpPosition, setTopUpPosition] = useState<IOpenPosition>();

  const topUpPositionPool = useMemo(() => {
    if (topUpPosition && poolsDataArray.length) {
      return poolsDataArray.find(
        (pool: ICollateralPool) => pool.id === topUpPosition.collateralPool
      ) as ICollateralPool;
    }

    return {} as ICollateralPool;
  }, [topUpPosition, poolsDataArray]);

  useEffect(() => {
    if (proxyWallet && account) {
      loadPositions({
        variables: {
          first: COUNT_PER_PAGE,
          skip: 0,
          walletAddress: proxyWallet,
        },
        fetchPolicy: "network-only",
      });
    } else {
      setFormattedPositions([]);
    }
  }, [chainId, proxyWallet, account, calledPositions, loadPositions]);

  const handlePageChange = useCallback(
    (event: ChangeEvent<unknown>, page: number) => {
      setIsLoading(true);
      fetchMorePositions({
        variables: {
          first: COUNT_PER_PAGE,
          skip: (page - 1) * COUNT_PER_PAGE,
          walletAddress: proxyWallet,
        },
      }).then(
        ({ data: { positions } }: { data: { positions: IOpenPosition[] } }) =>
          !positions.length && setIsLoading(false)
      );
      setPositionCurrentPage(page);
    },
    [proxyWallet, setPositionCurrentPage, setIsLoading, fetchMorePositions]
  );

  const fetchPositions = useMemo(
    () =>
      debounce((data, poolsDataArray: ICollateralPool[]) => {
        if (!data?.positions?.length) {
          return setFormattedPositions([]);
        }

        setIsLoading(true);

        const filteredPosition = data.positions.filter(
          (position: IOpenPosition) => position.positionStatus !== "closed"
        );

        const promises = filteredPosition.map((position: IOpenPosition) =>
          positionService.getDebtValue(
            position.debtShare,
            position.collateralPool
          )
        );

        Promise.all(promises).then((debtValues) => {
          const positions = filteredPosition.map(
            (position: IOpenPosition, index: number) => {
              const findPool = poolsDataArray.find(
                (pool: ICollateralPool) => pool.id === position.collateralPool
              ) as ICollateralPool;

              const debtValue = debtValues[index];

              const liquidationPrice = BigNumber(position?.debtValue)
                .dividedBy(position?.lockedCollateral)
                .multipliedBy(findPool?.liquidationRatio)
                .toNumber();

              const ltv = BigNumber(position.debtValue)
                .dividedBy(
                  BigNumber(findPool?.rawPrice).multipliedBy(
                    position.lockedCollateral
                  )
                )
                .toNumber();

              return {
                ...position,
                debtValue,
                liquidationPrice,
                ltv,
              };
            }
          );

          setFormattedPositions(positions);
          setIsLoading(false);
        });
      }, 300),
    [
      positionService,
      chainId,
      setFormattedPositions,
      setIsLoading,
      setFormattedPositions,
    ]
  );

  useEffect(() => {
    setIsLoading(loadingPositions);
  }, [loadingPositions, setIsLoading]);

  useEffect(() => {
    fetchPositions(positionsData, poolsDataArray);
  }, [positionsData, poolsDataArray, fetchPositions]);

  const onClose = useCallback(() => {
    setClosePosition(undefined);
    setTopUpPosition(undefined);
  }, [setClosePosition, setTopUpPosition]);

  return {
    topUpPositionPool,
    positions: formattedPositions,
    closePosition,
    topUpPosition,
    loading: isLoading,
    handlePageChange,
    setTopUpPosition,
    setClosePosition,
    onClose,
  };
};

export default useOpenPositionList;
