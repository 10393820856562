import { FC, useMemo } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Modal,
  Typography,
} from "@mui/material";
import { formatHashShorten, formatNumber } from "utils/format";
import { AppPaper } from "components/AppComponents/AppPaper/AppPaper";
import { FlexBox } from "components/AppComponents/AppBox/AppBox";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { TxVoteDetailModalTypes } from "hooks/Governance/useProposalItem";
import { IProposal } from "fathom-sdk";
import BigNumber from "bignumber.js";
import {
  LinearProgressAbstainsVoted,
  LinearProgressAgainstVoted,
  LinearProgressForVotes,
} from "./ProposalVoting";
import { ExtLinkIcon } from "components/Base/Buttons/StyledButtons";
import { getAccountUrl } from "utils/explorer";
import useConnector from "context/connector";

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalStyledTitle = styled(Typography)`
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
`;

const VoteProgressStatsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  border-radius: 8px;
  background: rgba(79, 101, 140, 0.2);
  margin-top: 16px;
  padding: 12px;
`;

const VoteProgressPercentage = styled(Typography)`
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
`;
const VoteProgressVotesCounter = styled(Typography)`
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
`;

const ParticipantsListWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  background: #2c4066;
  height: calc(100% - 156px);
`;

const ParticipantsListHeading = styled(Box)`
  color: #8ea4cc;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  border-bottom: 1px solid #1e2f4c;
  padding: 16px 32px;
`;

const ParticipantsListItem = styled(ListItem)`
  cursor: default;
  padding: 16px 24px;

  & .MuiListItemAvatar-root {
    min-width: 48px;
  }

  & .MuiListItemText-primary {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  }
  & .MuiListItemSecondaryAction-root {
    right: 24px;
  }
`;

const AvatarEmpty = styled("div")`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #43fff1;
`;

interface IVoters {
  id: string;
  address: string;
  vote: string;
  votingPower: string;
}

const ParticipantsList = ({ voters }: { voters: IVoters[] }) => {
  const { chainId } = useConnector();

  const openAddress = (address: string) => {
    const href = getAccountUrl(address, chainId);
    window.open(href, "_blank");
  };

  return (
    <ParticipantsListWrapper>
      <ParticipantsListHeading>{`Addresses (${voters.length})`}</ParticipantsListHeading>
      <List sx={{ padding: 0, overflowY: "auto" }}>
        {voters.map((voter) => (
          <ParticipantsListItem
            key={voter.id}
            secondaryAction={
              formatNumber(
                BigNumber(voter.votingPower)
                  .dividedBy(10 ** 18)
                  .toNumber()
              ) + " vFTHM"
            }
          >
            <ListItemAvatar sx={{ minWidth: "40px !important" }}>
              <AvatarEmpty />
            </ListItemAvatar>
            <>
              {formatHashShorten(voter.address)}{" "}
              <ExtLinkIcon
                sx={{ cursor: "pointer" }}
                width={"14px"}
                height={"14px"}
                onClick={() => openAddress(voter.address)}
              />
            </>
          </ParticipantsListItem>
        ))}
      </List>
    </ParticipantsListWrapper>
  );
};

type VoteProgressStatsProps = {
  proposal: IProposal;
  modalType: TxVoteDetailModalTypes;
  againstVotesPercent: string;
  forVotesPercent: string;
  abstainVotesPercent: string;
};

const VoteProgressStats: FC<VoteProgressStatsProps> = ({
  proposal,
  modalType,
  againstVotesPercent,
  forVotesPercent,
  abstainVotesPercent,
}) => {
  return (
    <VoteProgressStatsWrapper>
      <FlexBox>
        <VoteProgressPercentage>
          {modalType === TxVoteDetailModalTypes.AGAINST &&
            formatNumber(Number(againstVotesPercent))}
          {modalType === TxVoteDetailModalTypes.FOR &&
            formatNumber(Number(forVotesPercent))}
          {modalType === TxVoteDetailModalTypes.ABSTAINS &&
            formatNumber(Number(abstainVotesPercent))}
          %
        </VoteProgressPercentage>
        <VoteProgressVotesCounter>
          {modalType === TxVoteDetailModalTypes.AGAINST &&
            formatNumber(
              BigNumber(proposal?.againstVotes)
                .dividedBy(10 ** 18)
                .toNumber()
            )}
          {modalType === TxVoteDetailModalTypes.FOR &&
            formatNumber(
              BigNumber(proposal?.forVotes)
                .dividedBy(10 ** 18)
                .toNumber()
            )}
          {modalType === TxVoteDetailModalTypes.ABSTAINS &&
            formatNumber(
              BigNumber(proposal?.abstainVotes)
                .dividedBy(10 ** 18)
                .toNumber()
            )}{" "}
          Votes
        </VoteProgressVotesCounter>
      </FlexBox>
      {modalType === TxVoteDetailModalTypes.AGAINST && (
        <LinearProgressAgainstVoted
          variant="determinate"
          value={Number(againstVotesPercent)}
        />
      )}
      {modalType === TxVoteDetailModalTypes.FOR && (
        <LinearProgressForVotes
          variant="determinate"
          value={Number(forVotesPercent)}
        />
      )}
      {modalType === TxVoteDetailModalTypes.ABSTAINS && (
        <LinearProgressAbstainsVoted
          variant="determinate"
          value={Number(abstainVotesPercent)}
        />
      )}
    </VoteProgressStatsWrapper>
  );
};

type TxVoteDetailModalProps = {
  isOpen: boolean;
  proposal: IProposal;
  onClose: () => void;
  modalType: TxVoteDetailModalTypes;
  againstVotesPercent: string;
  forVotesPercent: string;
  abstainVotesPercent: string;
};

const TxVoteDetailModal: FC<TxVoteDetailModalProps> = ({
  proposal,
  isOpen,
  onClose,
  modalType,
  againstVotesPercent,
  forVotesPercent,
  abstainVotesPercent,
}) => {
  const voters = useMemo(() => {
    // @ts-ignore
    return proposal?.voters.filter((voter) => voter.vote === modalType);
  }, [proposal]);

  return (
    <StyledModal onClose={onClose} open={isOpen}>
      <AppPaper sx={{ width: "500px", height: "485px", overflow: "hidden" }}>
        <FlexBox sx={{ flexDirection: "column", padding: "24px" }}>
          <FlexBox>
            <ModalStyledTitle>
              {
                Object.entries(TxVoteDetailModalTypes).find(
                  (item) => item[1] === modalType
                )?.[0]
              }
            </ModalStyledTitle>
            <IconButton onClick={onClose} sx={{ padding: 0 }}>
              <CloseRoundedIcon
                sx={{ color: "#fff", width: "24px", height: "24px" }}
              />
            </IconButton>
          </FlexBox>
          <VoteProgressStats
            proposal={proposal}
            modalType={modalType}
            againstVotesPercent={againstVotesPercent}
            forVotesPercent={forVotesPercent}
            abstainVotesPercent={abstainVotesPercent}
          />
        </FlexBox>
        <ParticipantsList voters={voters} />
      </AppPaper>
    </StyledModal>
  );
};

export default TxVoteDetailModal;
